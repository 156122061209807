import { useState } from "react";
import { Box, Button, HStack, Input, Text, FormControl, FormErrorMessage } from "@chakra-ui/react";
import ToastMessage from "../utility/ToastMessage";
import axios from "axios";

export default function IdCardNoForm({
  user_id, id_card_no, apply_id = null
}) {
  const [inputValue, setInputValue] = useState(id_card_no || '');
  const [isValid, setIsValid] = useState(true);
  const token = localStorage.getItem('my_token');

  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only numeric input
    if (!/^\d*$/.test(value)) return;

    setInputValue(value);

    // Validate if it's exactly 6 or 10 digits
    setIsValid((value.length >= 6 && value.length <= 12) || value.length === 0);
  };

  const handleSubmit = () => {
    let config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    const params = {
      id_card_no: inputValue,
      apply_id: apply_id
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/update-id-card-no/${user_id}`, params, config)
      .then((response) => {
        ToastMessage('success', 'Successfully Updated');
      })
      .catch((error) => {
        const { status } = error?.response;

        let message = 'Something went wrong';
        if (status == 422) {
          message = 'Invalid Input';
        }

        ToastMessage('error', message);
      });
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" m={4}>
      <FormControl isInvalid={!isValid} onSubmit={handleSubmit}>
        <HStack spacing={4} justifyContent="center">
          <Text fontWeight="bold">RF Card Number:</Text>
          <Input
            placeholder="Enter 6 to 12 digits card No"
            value={inputValue}
            onChange={handleChange}
            width="250px"
            maxLength={10}
          />
          <Button type="submit" colorScheme="blue" isDisabled={!isValid || inputValue.length === 0} onClick={handleSubmit}>
            Submit
          </Button>
        </HStack>
        {!isValid && <FormErrorMessage>Input must be within 6 to 12 digits.</FormErrorMessage>}
      </FormControl>
    </Box>
  );
}
